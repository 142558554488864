<template>
  <div id="printTest" class="tihuodan" style="width: 980px; padding: 35px 8px 18px; background-color: #fff; font-size: 14px;">
    <div class="toubu" style="display: flex; align-items: center; justify-content: space-between; color: #333;">
      <img style="height: 42px;" :src="$localPicture+'logo/logo_black.png'" alt="logo">
      <span>{{ detailObj.settleNo }}</span>
    </div>
    <div style="padding: 12px 0 24px; text-align: center;">
      <h3 style="text-align-last: justify; text-align: justify; text-justify: distribute-all-lines; font-size: 18px; font-weight: bold; text-decoration: underline; width: 68px; margin: auto;">
        结算单
      </h3>
    </div>
    <div style="text-align:right;padding-bottom: 2px;">
      编制日期：{{ detailObj.compileDate }}
    </div>
    <table class="tableBox" style="text-align: center;border-collapse: collapse; text-indent: 6px; width: 100%; border: none;">
      <tbody>
        <tr style="font-size: 13px; height: 36px;text-align: left;">
          <td style="width: 12%; border: 1px solid #dddddd;border-bottom: 0; height: 36px;">
            经销商单位
          </td>
          <td style="border: 1px solid #dddddd;border-bottom: 0; height: 36px; width: 36%;">
            {{ detailObj.custCmpName }}
          </td>
          <td style="width: 12%; border: 1px solid #dddddd;border-bottom: 0; height: 36px;">
            代采商单位
          </td>
          <td style="border: 1px solid #dddddd;border-bottom: 0; height: 36px; width: 38%;">
            {{ detailObj.fundsCmpName }}
          </td>
        </tr>
      </tbody>
    </table>
    <table class="tableBox" style="text-align: center;border-collapse: collapse; text-indent: 6px; width: 100%; border: none;">
      <tbody>
        <tr style="height: 36px;">
          <td style="width:5%; border: 1px solid #dddddd; height: 36px;">
            序号
          </td>
          <td style="width: 8%; border: 1px solid #dddddd; height: 36px;">
            品名
          </td>
          <td style=" width: 10%;border: 1px solid #dddddd; height: 36px;">
            材质
          </td>
          <td style=" width: 13.5%;border: 1px solid #dddddd; height: 36px;">
            规格
          </td>
          <td style=" width: 11.5%;border: 1px solid #dddddd; height: 36px;">
            产地
          </td>
          <td style="width: 10%;border: 1px solid #dddddd; height: 36px;">
            件重/吨
          </td>
          <td style="border: 1px solid #dddddd; height: 36px;">
            结算数量
          </td>
          <td style="border: 1px solid #dddddd; height: 36px;">
            计量单位
          </td>
          <td style=" border: 1px solid #dddddd; height: 36px;">
            结算单价(元)
          </td>
          <td style=" border: 1px solid #dddddd; height: 36px;">
            结算金额(元)
          </td>
        </tr>
        <!-- 这里是循环渲染的产品 -->
        <tr v-for="(item,index) in detailObj.goodsPrintRespList" :key="index" style="height: 36px;">
          <td style="width:6%;border: 1px solid #dddddd; height: 36px;">
            {{ index + 1 }}
          </td>
          <td style="border: 1px solid #dddddd; height: 36px;">
            {{ item.goodsName }}
          </td>
          <td style="border: 1px solid #dddddd; height: 36px;">
            {{ item.goodsMaterials }}
          </td>
          <td style="border: 1px solid #dddddd; height: 36px;">
            {{ item.goodsSpecs }}
          </td>
          <td style="border: 1px solid #dddddd; height: 36px;">
            {{ item.goodsProduct }}
          </td>
          <td style="border: 1px solid #dddddd; height: 36px;">
            {{ item.goodsWeights }}
          </td>
          <td style="border: 1px solid #dddddd; height: 36px;">
            {{ item.settleWeight }}
          </td>
          <td style="border: 1px solid #dddddd; height: 36px;">
            {{ item.unit || '吨' }}
          </td>
          <td style="border: 1px solid #dddddd; height: 36px;">
            {{ item.settlePrice }}
          </td>
          <td style="border: 1px solid #dddddd; height: 36px;">
            {{ item.totalSettlement || 0 }}
          </td>
        </tr>
        <tr style="height: 36px;">
          <td colspan="2" style="text-align: left;border: 1px solid #dddddd; height: 36px;">
            合计
          </td>
          <td colspan="4" style="border: 1px solid #dddddd; height: 36px;" />
          <td style="border: 1px solid #dddddd; height: 36px;">
            {{ detailObj.totalSettleWeight }}
          </td>
          <td colspan="2" style="border: 1px solid #dddddd; height: 36px;" />
          <td style="border: 1px solid #dddddd; height: 36px;">
            ￥{{ detailObj.settleTotalAmount }}
          </td>
        </tr>
        <tr style="height: 36px;">
          <td colspan="2" style="text-align: left;border: 1px solid #dddddd; height: 36px;">
            人民币大写
          </td>
          <td colspan="8" style=" border: 1px solid #dddddd; height: 36px;">
            {{ detailObj.settleTotalAmountFormat }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import print from 'print-js'
import { agentSettlePrintSettleDocData } from '@/http/agentApi/financialManage'
export default {
  props: {
    productId: {
      default: '',
      type: [String, Number]
    }
  },
  data() {
    return {
      detailObj: {}
    }
  },
  watch: {
    productId: {
      handler(newVal) {
        if (newVal && newVal.indexOf('&&') >= 0) {
          const arr = newVal.split('&&')
          agentSettlePrintSettleDocData(arr[0], res => {
            this.detailObj = { ...res.data }
            let totalWeight = 0
            totalWeight = res.data.goodsPrintRespList.reduce((prev, next) => prev + next.settleWeight, 0)
            this.detailObj.totalSettleWeight = totalWeight
            this.$nextTick(() => {
              print({
                printable: 'printTest',
                type: 'html',
                scanStyles: false,
                repeatTableHeader: false,
                targetStyles: ['*']
              })
            })
          })
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
