<template>
  <el-dialog class="selectAccountPageBox" title="选择支付账户" :visible.sync="dialogTableVisible" :close-on-click-modal="false">
    <p v-if="Number(money)>=0" class="aggregateAmount">
      需支付金额：<span>{{ Math.abs(money) | numberToCurrency }}</span>元
    </p>
    <Table
      ref="tableList"
      :item-data="accountItemData"
      :list-data="accountListData"
      :radio-show="true"
      @getCheckData="getCheckData"
    />
    <p v-if="accountListData.length===0" class="noBankBox">
      没有可支付账户，<router-link :to="{path:'/enterpriseInformation/bankAccountManagement'}">
        请添加
      </router-link>
    </p>
    <div slot="footer" class="dialog-footer">
      <el-button class="widen-button" size="small" @click="dialogTableVisible = false">
        取 消
      </el-button>
      <el-button class="widen-button" size="small" type="primary" @click="getAccountValue">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import Table from './Table'
import { accountInfoPage } from '@/http/agentApi/enterpriseInformation'
export default {
  components: { Table },
  props: {
    money: {
      default: 0,
      type: [Number, String]
    },
    settleBankId: {
      default: '',
      type: String
    },
    checkSettleBankObj: {
      default: () => { return {} },
      type: Object
    }
  },
  data() {
    return {
      formInline: {
        pageSize: 1000,
        pageNum: 1
      },
      total: 0,
      accountItemData: [
        { label: '结算银行 ', prop: 'settleBankName', width: 180 },
        { label: '总金额', prop: 'totalFund', width: 120, type: 'money' },
        { label: '可用余额', prop: 'avlbFund', width: 120, type: 'money' },
        { label: '冻结金额 ', prop: 'frzFund', width: 120, type: 'money' },
        { label: '可提现金额 ', prop: 'avlbCashFund', width: 120, type: 'money' }
      ],
      accountListData: [],
      dialogTableVisible: false,
      checkRow: {}
    }
  },
  watch: {
    settleBankId: {
      handler(newVal) {
        this.getdata()
      },
      immediate: true,
      deep: true
    },
    dialogTableVisible: {
      handler(newVal) {
        if (newVal && this.checkSettleBankObj && this.checkSettleBankObj.id) {
          this.accountListData = [{ ...this.checkSettleBankObj }]
          this.total = 1
          this.$nextTick(() => {
            this.$refs.tableList.radioRow = this.checkSettleBankObj.id
            this.checkRow = { ...this.checkSettleBankObj }
          })
          return
        }
        if (newVal) this.getdata()
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    // 如果有选择好的就不要去请求接口了
    if (!this.checkSettleBankObj.id && !this.settleBankId) this.getdata()
  },
  methods: {
    getCheckData(row) {
      this.checkRow = { ...row }
    },
    getAccountValue() {
      if (!this.checkRow.id) {
        this.$message.error('请选择一个金额不为0的账户')
        return
      }
      this.$emit('getAccount', this.checkRow)
      // this.dialogTableVisible = false
    },
    getdata(type) {
      this.formInline.settleBankId = this.settleBankId
      this.formInline.cmpId = JSON.parse(localStorage.getItem(`${sessionStorage.getItem('systemPlatformRoles') === '03' ? 'userInfoCust' : 'userInfoAgent'}`)).cmpId
      if (!type) {
        this.formInline.pageNum = 1
      }
      accountInfoPage(this.formInline, res => {
        let obj = {}
        // 把已经选好的拿出来
        this.accountListData = res.data.pageData.map((item) => {
          if (item.settleBankId === this.settleBankId) {
            obj = { ...item }
          }
          if (item.settleBankId === 'ABC') {
            item.totalFund = '—'
            item.avlbFund = '—'
            item.frzFund = '—'
            item.avlbCashFund = '—'
          }
          return item
        })
        this.total = res.data.total
        if (obj.id) {
          setTimeout(() => {
            this.checkRow = { ...obj }
            this.$refs.tableList.radioRow = obj.id
          }, 200)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.selectAccountPageBox{
  .noBankBox{
    text-align: center;
    margin-top: 12px;
    color: #333;
    font-size: 14px;
    a{
      color: $mainBlue;
    }
  }
  .dialog-footer{
    text-align: center;
  }
  .aggregateAmount {
    padding-bottom: 18px;
    color: $dominantColor;

    span {
      color: $pointClearColor;
      font-size: 17px;
    }
  }
}
</style>
